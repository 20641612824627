import type { RouterConfig } from "@nuxt/schema";

export default <RouterConfig>{
    scrollBehavior(to, from) {
        if (to.hash) {
            return { el: to.hash, behavior: "smooth" };
        }

        if (
            to.path.includes("collections") ||
            from.path.includes("collections")
        ) {
            if (to.path.split("/").pop() === from.path.split("/").pop()) {
                return false;
            }
        }

        if(to.path === from.path){
            return false;
        }

        return { top: 0 };
    },
};
