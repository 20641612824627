<template>
	<div class="flex flex-col justify-center items-center hero  text-white h-[100dvh] gap-8">
		<video src="/videos/hero.mp4" muted autoplay playsinline loop class="absolute top-0 left-0 w-full h-full object-cover"></video>
			<h1 class="z-10 text-white text-5xl leading-[3rem] lg:max-w-3xl lg:text-8xl lg:leading-[98px] text-center w-full md:w-2/3">
				Unleashing generative art on Bitcoin
			</h1>
			<NuxtLink to="/collections" class="text-white underline z-10">
				Discover our collections
			</NuxtLink>

	</div>
</template>

<style scoped> 

.hero {
	/* background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('/images/collections/artifakt/artifakt-bg.webp'); */
	background-size: cover;
	background-position: center center;
}

</style>
