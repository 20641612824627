<template>
    <div class="grid grid-cols-4 gap-x-4 w-full 
    justify-between font-medium text-[8px] md:text-xs leading-[10px] text-center [&_img]:w-[35px] [&_img]:m-auto [&_img]:mb-2">
        <div>

            <img src="/public/images/chefs-doeuvre/utils/banner/cc.png" alt="">
CC BY NC
        </div>
        <div>

            <img src="/public/images/chefs-doeuvre/utils/banner/check.png" alt="">
            PARENT/CHILD <br> PROVENANCE
        </div>
        <div>

            <img src="/public/images/chefs-doeuvre/utils/banner/sats.png" alt="">
            UNCOMMON <br> SATS
        </div>
        <div>

            <img src="/public/images/chefs-doeuvre/utils/banner/auction.png" alt="">
            FAIR ENGLISH <br> AUCTION
        </div>
    </div>
</template>