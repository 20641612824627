
import * as ipxRuntime$bLXOQnc2jF from '/app/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "carousel": {
      "modifiers": {
        "fit": "contain",
        "width": 800,
        "height": 800,
        "b": "#FFFFFF"
      }
    },
    "carouselMd": {
      "modifiers": {
        "fit": "contain",
        "width": 1200,
        "height": 1200,
        "b": "#FFFFFF"
      }
    },
    "details": {
      "modifiers": {
        "fit": "contain",
        "width": 800,
        "height": 1422,
        "b": "#FFFFFF"
      }
    },
    "detailsMd": {
      "modifiers": {
        "fit": "contain",
        "width": 1200,
        "height": 1200,
        "b": "#FFFFFF"
      }
    }
  },
  "provider": "ipx",
  "domains": [
    "vivid-render.s3.eu-west-1.amazonaws.com",
    "s3.vivid.gallery"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$bLXOQnc2jF, defaults: {} }
}
        