import * as Sentry from '@sentry/nuxt';

Sentry.init({
  // If set up, you can use your runtime config here
  dsn: "https://2d4196acbe338ec149630d27536e5051@o4508003581820928.ingest.de.sentry.io/4508006554140752",
  denyUrls: ["localhost", "127.0.0.1"],
  integrations: [Sentry.browserTracingIntegration()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.5, 
  
});