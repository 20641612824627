export default defineNuxtRouteMiddleware((to) => {
  if (to.path === "/collections/floraforms") {
    return navigateTo("/collaborations/floraforms", {redirectCode: 301});
  }



  if(to.path.includes("collections") || to.path.includes("collaborations") || to.path.includes("chefs-doeuvre")) {

    if(to.path.includes("about")){

      return navigateTo(to.path.replace("/about", ""), {redirectCode: 301});
    }

    if(to.path.includes("browse")){

      return navigateTo(to.path.replace("/browse", "").concat("?tab=browse"), {redirectCode: 301, });
    }

  }


});
