<template>
  <svg
    width="100"
    height="16"
    viewBox="0 0 100 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0.637207H3.33767L7.8069 11.2136L12.3046 0.637207H15.6342L9.25594 15.3667H6.39043L0 0.637207Z"
      fill="#1D1D1B"
    />
    <path
      d="M27.6457 15.3625V0.637207H30.6984V15.3667H27.6457V15.3625Z"
      fill="#1D1D1B"
    />
    <path
      d="M42.71 0.637207H46.0477L50.5169 11.2136L55.0146 0.637207H58.3442L51.966 15.3667H49.1004L42.71 0.637207Z"
      fill="#1D1D1B"
    />
    <path
      d="M70.3558 15.3625V0.637207H73.4085V15.3667H70.3558V15.3625Z"
      fill="#1D1D1B"
    />
    <path
      d="M85.6114 16V12.5842L95.946 8.01041L85.6114 3.40745V0L100.004 6.52747V9.46004L85.6114 16Z"
      fill="#1D1D1B"
    />
  </svg>
</template>
