<script setup lang="ts">
let youtubeVideoOpen = ref(false);

const loadYoutubeVideo = () => {
  youtubeVideoOpen.value = true;
};
</script>
<template>
  <div class="flex w-full cursor-pointer" @click="loadYoutubeVideo">
    <div class="relative lg:max-h-[954px] w-full flex">
      <video
        v-if="!youtubeVideoOpen"
        src="/videos/artist-retreat.mp4"
        muted
        autoplay
        playsinline
        loop
        class="top-0 left-0 w-full aspect-video"
      ></video>

      <iframe
        v-if="youtubeVideoOpen"
        class="w-full aspect-video"
        src="https://www.youtube.com/embed/7gvhY0HuP_w?si=SxbksYKq4cXIEUt1?&autoplay=1"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
        aspectRatio="16/9"
      ></iframe>
      <div
        v-if="!youtubeVideoOpen"
        class="absolute text-white text-sm z-10 lg:text-4xl flex flex-col text-left p-4 lg:p-16"
      >
        <span>Discover our</span>
        <span>last artist retreat short-film</span>
      </div>
      <button
        v-if="!youtubeVideoOpen"
        
        class="absolute z-20 text-white bottom-0 right-0 m-4 lg:m-16 p-1 lg:p-2 uppercase border-white border text-sm lg:text-xl font-medium"
      >
        Play video
      </button>
    </div>
  </div>
</template>
