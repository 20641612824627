
interface TwitterEvents {
  MEViewCollection: string;
  JoinDiscord: string;
}

export const statuses = {
  secondary: "Secondary Market",
  onSale: "On Sale",
  upcoming: "Upcoming",
};

export const twitterEvents: TwitterEvents = {
  JoinDiscord: "tw-omaak-ompza",
  MEViewCollection: "tw-omaak-omv8p",
};
