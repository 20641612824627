import { twitterEvents } from "../utils/constants";

interface BaseEvent {
  name: string;
  options?: Record<string, string | number>;
}

export interface JoinDiscordEvent extends BaseEvent {
  name: "JoinDiscord";
}

export interface MEViewCollectionItem extends BaseEvent {
  name: "MEViewCollectionItem";
  collection: string;
  id: string;
  itemName: string;
}

export interface MEViewCollection extends BaseEvent {
  name: "MEViewCollection";
  collection: string;
}

export type TrackingEvent =
  | JoinDiscordEvent
  | MEViewCollectionItem
  | MEViewCollection;

declare const twq: (event: string, name: string, options: Record<string, any>) => void;

export function useTracking() {
  function track(event: TrackingEvent) {
    if (event.name === "JoinDiscord") {
      twq("event", twitterEvents.JoinDiscord, {});
    }

    if (event.name === "MEViewCollectionItem") {
      twq("event", twitterEvents.MEViewCollection, {
        contents: [
          {
            content_type: event.collection,
            content_id: event.id,
            content_name: event.itemName,
            content_price: null,
            num_items: null,
            content_group_id: null,
          },
        ],
      });
    }

    if (event.name === "MEViewCollection") {
      twq("event", twitterEvents.MEViewCollection, {
        contents: [
          {
            content_type: event.collection,
            content_id: null,
            content_name: null,
            content_price: null,
            num_items: null,
            content_group_id: null,
          },
        ],
      });
    }
  }

  return { track };
}
